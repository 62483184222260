import React from "react"
import BillPayments from "../../../../components/body/pages/en-ng/business/bill-payments"
import Layout from "../../../../components/layout"
import SEO from "../../../../components/seo"

const BillPaymentsPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/business/bill-payments/"}
      title="Pay Bills Online | Kuda for Business"
      description="Streamline your bill payments & utilities, stay organised, & focus on what matters most - growing your business. Explore Kuda's business solutions today!"
    />
    <BillPayments />
  </Layout>
)

export default BillPaymentsPage
